import { useEffect, useState } from "react";
import { BACKEND_URL } from "./WebConfig";
import "./YourCards.css";
import AddCard from "./AddCard";

function Card({ info }) {
  return (
    <div className="card">
      <h3>{info.card_text}</h3>
    </div>
  );
}

function CardCollection({ cards }) {
  const cardElems = cards.map((card) => <Card info={card} />);
  return cardElems;
}

function DownloadReportButton({ sessionId }) {
  const onClick = async (session_id, form_args) => {
    let fd = new FormData();
    for (let k in form_args) {
      fd.append(k, form_args[k]);
    }
    await fetch(BACKEND_URL + "/report", {
      method: "post",
      headers: {
        session_id: sessionId,
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        var file = new Blob([blob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        var url = URL.createObjectURL(file);
        var link = document.createElement("a");
        link.download = "Report.xlsx";
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };
  return <button onClick={onClick}>Download Spreadsheet</button>;
}

export default function YourCards({ session_id }) {
  const [cards, setCards] = useState([]);

  const [pending, setPending] = useState(0);

  const [showAddForm, setShowAddform] = useState(false);

  const handleResponse = (r) => {
    if (r.status === 200) {
      r.json().then(setCards);
    }
  };

  const requestCards = () => {
    fetch(BACKEND_URL + "/my_cards", {
      headers: { session_id: session_id },
    }).then(handleResponse);
  };

  const showAddCardForm = () => {
    setShowAddform(true);
  };

  useEffect(requestCards, [pending]);

  return (
    <div>
      <div className="flex-col flex-center">
        <div className="flex-row" id="card-page-header">
          <h3 id="your-cards-heading">Your Cards:</h3>
          <button id="add-card-btn" onClick={showAddCardForm}>
            Add
          </button>
          <DownloadReportButton sessionId={session_id} />
        </div>
        <CardCollection cards={cards} />
      </div>
      {showAddForm && (
        <AddCard
          sessionId={session_id}
          setShow={setShowAddform}
          setPending={setPending}
          pending={pending}
        />
      )}
    </div>
  );
}
